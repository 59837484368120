import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {ApplicationModel} from "../../types";

export interface AuthSliceState {
  applications: ApplicationModel[]
  application?: ApplicationModel
}

const initialState: AuthSliceState = {
  applications: [],
  application: undefined,
}

export const applicationSlice = createAppSlice({
  name: 'application',
  initialState,
  reducers: create => ({
    setApplications: create.reducer((state, action: PayloadAction<ApplicationModel[]>) => {
      state.applications = action.payload.filter(a => !!a);
    }),
    setApplication: create.reducer((state, action: PayloadAction<ApplicationModel | undefined>) => {
      if (!action.payload) {
        return;
      }
      state.application = action.payload;
      const applications = state.applications.filter(a => !!a);
      const index = applications.findIndex(a => a.pk === action.payload?.pk)
      if (index === -1) {
        applications.push(action.payload!)
      } else {
        applications[index] = action.payload!
      }
      state.applications = applications;
    }),
  }),
  selectors: {
    selectApplications: application => application.applications,
    selectApplication: application => application.application,
  },
});

export const { setApplications, setApplication } =
  applicationSlice.actions

export const { selectApplications, selectApplication } = applicationSlice.selectors
