import React, {useRef, useState} from 'react';
import {Modal} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import {KTIcon} from "../../../../_mesomb/helpers";
import {useAppDispatch, useAppSelector} from "../../../store/hooks.ts";
import {selectUser, setUser} from "../../../store/auth.ts";
import clsx from "clsx";
import {useNavigate} from "react-router";
import ProfileForm from "./ProfileForm.tsx";
import BusinessForm from "./BusinessForm.tsx";
import {setBankAccounts} from "../../../store/account.ts";
import BankAccountForm from "./BankAccountForm.tsx";
import HModal from "../../../../_mesomb/partials/components/HModal.tsx";

export interface ProfileVerificationWizardProps {
  show: boolean;
  handleClose: () => void;
}

function ProfileVerificationWizard({show, handleClose}: ProfileVerificationWizardProps) {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const currentUser = useAppSelector(selectUser)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [step, setStep] = useState(0)
  const dispatch = useAppDispatch();

  const skipStep = () => {
    setStep(step + 1)
  }

  return (
    <HModal
      aria-hidden='true'
      show={show}
      handleClose={handleClose}
      backdrop={true}
      title={t('Verify your Profile')}
    >
      <div
        ref={stepperRef}
        className='stepper stepper-links d-flex flex-column'
        id='kt_create_account_stepper'
      >
        <div className='stepper-nav mb-5'>
          <div className={clsx('stepper-item', {current: step == 0})} data-cy={'stepper-personal-info'} data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>{t('Personal Info')}</h3>
          </div>

          <div className={clsx('stepper-item', {current: step == 1})} data-cy={'stepper-business-info'} data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>{t('Business Info')}</h3>
          </div>

          <div className={clsx('stepper-item', {current: step == 2})} data-cy={'stepper-bank-account'} data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>{t('Bank Account')}</h3>
          </div>

          <div className={clsx('stepper-item', {current: step == 3})} data-cy={'stepper-confirmation'} data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>{t('Confirmation')}</h3>
          </div>
        </div>
        {step == 0 && (
          <ProfileForm
            profile={currentUser!}
            action={{
              path: '/profile/personal/',
              method: 'put'
            }}
            onSubmit={(data) => {
              dispatch(setUser(data))
              setStep(step + 1)
            }}
          />
        )}
        {step == 1 && (
          <BusinessForm
            profile={currentUser!}
            action={{
              path: '/profile/business/',
              method: 'put'
            }}
            onSubmit={(data) => {
              dispatch(setUser(data))
              setStep(step + 1)
            }}
          />
        )}
        {step == 2 && (
          <BankAccountForm
            action={{
              path: `/bank/accounts/`,
              method: 'post'
            }}
            onSubmit={(data) => {
              dispatch(setBankAccounts([data]))
              setStep(step + 1)
            }}
          />
        )}
        {step == 3 && (
          <div className="w-100">
            <div className="pb-12 text-center">
              <h1 className="fw-bold text-dark mb-6">{t('Profile Submitted')}!</h1>
              <div className="fw-semibold text-muted fs-4">
                {t('Your profile has been successfully submitted for activation.')} <br/> {t('Our team will validate it and you will be notified.')}
              </div>
            </div>

            <div className="d-flex flex-center pb-20">
              <button data-cy={'btn-confirm-onboarding'} onClick={() => {
                handleClose()
                navigate('/account/profile')
              }} className="btn btn-lg btn-light-primary">{t('Go to Profile')}</button>
            </div>
          </div>
        )}
        <div>
          {step > 0 && (
            <button
              onClick={skipStep}
              type='button'
              className='btn btn-lg btn-light-primary me-3'
              data-cy={'btn-stepper-skip'}
            >
              {t('Skip')}
              <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0'/>
            </button>
          )}
        </div>
      </div>
    </HModal>
  );
}

export default ProfileVerificationWizard;
