import React, {useMemo} from 'react';
import * as Yup from "yup";
import {FormAction, UserModel} from "../../../../types";
import {useTranslation} from "react-i18next";
import FormBuilder from "../../../../_mesomb/partials/form/FormBuilder.tsx";
import i18n from "../../../../_mesomb/i18n";

export interface ProfileFormProps {
  action: FormAction;
  onSubmit: (values: UserModel) => void;
  profile: UserModel;
}

function ProfileForm({action, onSubmit, profile, ...props}: ProfileFormProps) {
  const {t} = useTranslation();

  const formSchema = useMemo(() => Yup.object().shape({
    full_name: Yup.object({
      last_name: Yup.string().required(t('At least the last name is required')),
      first_name: Yup.string()
    }),
    gender: Yup.string().oneOf(['MAN', 'WOMAN']).required(t('Field_is_required')),
    town: Yup.string().required(t('Field_is_required')),
    region: Yup.string().required(t('Field_is_required')),
    country: Yup.string().required(t('Field_is_required')),
    avatar: Yup.string().required(t('Field_is_required')),
    birth_date: Yup.date().max(new Date(), t('Invalid_birth_date')).required(t('Field_is_required')),
    id_card_type: Yup.string().oneOf(['NIC', 'PASSPORT', 'TEMP_ID', 'DRIVER_LICENCE', 'VOTER_CARD']).required(t('Field_is_required')),
    id_card_number: Yup.string().required(t('Field_is_required')).matches(/^[A-Z0-9]+$/, t('Invalid_id_card_number')),
    id_card_expiry: Yup.date().min(new Date(), t('Invalid_card_expiry_date')).required(t('Field_is_required')),
    id_card_front: Yup.string().required(t('Field_is_required')),
    id_card_back: Yup.string().required(t('Field_is_required')),
    phone: Yup.string().required(i18n.t('Field_is_required')).matches(/^[+]?[0-9]{11,12}$/, i18n.t('Invalid_phone_number')),
    // b_party: Yup.string().matches(/^[0-9]{8,9}$/, t('Invalid_phone_number')).required(t('Field_is_required')),
  }), []);
  type FormProfileValues = Yup.InferType<typeof formSchema>;

  return (
    <>
      <div className='notice d-flex bg-light-info rounded border-info border border-dashed p-6 mb-5'>
        <ul>
          <li>{t('Information written must be the same as on your ID documents.')}</li>
          <li>{t('Your ID documents must be valid.')}</li>
          <li>{t('Your phone number must be identified with your name.')}</li>
        </ul>
      </div>
      <FormBuilder<FormProfileValues, UserModel>
        fieldsets={[{
          cols: [
            {
              size: 'col-lg-6 col-md-12',
              fields: [
                {
                  type: 'full_name',
                  name: 'full_name',
                  label: t('Full Name'),
                },
                {
                  name: 'gender',
                  label: t('Gender'),
                  type: 'radio',
                  options: [
                    {value: 'MAN', label: t('Man')},
                    {value: 'WOMAN', label: t('Woman')},
                  ],
                  orientation: 'horizontal',
                },
                {
                  name: 'town',
                  label: t('Town'),
                  type: 'text',
                },
                {
                  name: 'country',
                  type: 'country',
                  label: t('Country'),
                },
                {
                  name: 'region',
                  label: t('Region'),
                  type: 'text',
                },
              ]
            },
            {
              size: 'col-lg-6 col-md-12',
              fields: [
                {
                  type: 'phone',
                  name: 'phone',
                  label: t('Phone Number'),
                },
                {
                  type: 'date',
                  name: 'birth_date',
                  label: t('Birth Date'),
                },
                {
                  type: 'select',
                  name: 'id_card_type',
                  label: 'ID Card Type',
                  options: [
                    {value: 'NIC', label: t('National Identity Card')},
                    {value: 'PASSPORT', label: t('Passport')},
                    {value: 'TEMP_ID', label: t('Temporary Identity')},
                    {value: 'DRIVER_LICENCE', label: t('Driver Licence')},
                    {value: 'VOTER_CARD', label: t('Voter Card')},
                  ],
                },
                {
                  type: 'text',
                  name: 'id_card_number',
                  label: t('ID Card Number'),
                },
                {
                  type: 'date',
                  name: 'id_card_expiry',
                  label: t('ID Card Expiry Date'),
                },
              ]
            },
          ]
        }, {
          cols: [
            {
              size: 'col-lg-4 col-md-12',
              fields: [
                {
                  name: 'avatar',
                  label: t('Your Picture'),
                  type: 'dropzone',
                  placeholder: t('Upload a selfie'),
                  file: profile.avatar,
                },
              ]
            },
            {
              size: 'col-lg-4 col-md-12',
              fields: [
                {
                  name: 'id_card_front',
                  label: t('ID Front Card'),
                  type: 'dropzone',
                  placeholder: t('Upload the front of your ID card'),
                  file: profile.id_card_back,
                  accept: {'image/*': [], 'application/pdf': []},
                },
              ]
            },
            {
              size: 'col-lg-4 col-md-12',
              fields: [
                {
                  name: 'id_card_back',
                  label: t('ID Back Card'),
                  type: 'dropzone',
                  required: false,
                  file: profile.id_card_back,
                  placeholder: t('Upload the back of your ID card'),
                  accept: {'image/*': [], 'application/pdf': []},
                },
              ]
            },
          ]
        }]}
        formSchema={formSchema}
        className={''}
        action={action}
        onSubmit={onSubmit}
        initialValues={{
          full_name: {
            first_name: profile.first_name,
            last_name: profile.last_name,
          },
          gender: profile.gender,
          town: profile.town ?? '',
          region: profile.region ?? '',
          country: profile.country ?? '',
          avatar: String(profile.avatar?.id ?? ''),
          birth_date: profile.birth_date,
          id_card_type: profile.id_card_type ?? 'NIC',
          id_card_number: profile.id_card_number ?? '',
          id_card_expiry: profile.id_card_expiry,
          id_card_front: String(profile.id_card_front?.id ?? ''),
          id_card_back: String(profile.id_card_back?.id ?? ''),
          phone: profile.phone_number ?? '',
        }}
        {...props}
      />
    </>
  );
}

export default ProfileForm;
