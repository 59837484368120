import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Modal, ModalBody} from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import {useFormik} from "formik";
import axios from "axios";
import * as Yup from "yup";
import i18n from "../../../../_mesomb/i18n";
import ButtonIndicator from "../../../../_mesomb/partials/components/ButtonIndicator.tsx";
import {useAppDispatch} from "../../../store/hooks.ts";
import {setTerms} from "../../../store/terms.ts";
import {TermAndConditionModel} from "../../../../types";
import {captureException} from "@sentry/react";

const formSchema = Yup.object().shape({
  agree: Yup.bool().required(i18n.t('You must accept the terms and conditions')),
})
type FormTermValues = Yup.InferType<typeof formSchema>

function AcceptTermsAndConditions({terms}: {terms: TermAndConditionModel}) {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const [showModal, setShowModal] = useState(false)
  const formik = useFormik<FormTermValues>({
    initialValues: {
      agree: false,
    },
    validationSchema: formSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      try {
        await axios.post('terms/accept/', {terms: [terms.id]})
        setSubmitting(false);
        setShowModal(false);
        dispatch(setTerms(undefined))
      } catch (error) {
        setSubmitting(false);
        captureException(error);
      }
    },
  })
  return (
    <>
      <div className="alert bg-warning d-flex flex-column flex-sm-row p-2 mb-0 justify-content-center mb-5" style={{borderRadius: 0}}>
        <div className="d-flex flex-column text-light pe-0 pe-sm-10">
        <span
          className="fs-3 fw-semibold">{t('Message_terms_and_conditions_notification')}</span>
        </div>

        <a onClick={() => setShowModal(true)}
           data-cy={'btn-read-accept-terms'}
           className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-sm btn-light ms-sm-auto request-verification">
          {t('Read and Accept')}
        </a>
      </div>

      <Modal dialogClassName={`modal-dialog modal-dialog-centered mw-1000px`} show={showModal}>
        <ModalBody>
          <Markdown>{terms.text}</Markdown>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='fv-row mb-8'>
              <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='id_toc_agree'
                  {...formik.getFieldProps('agree')}
                />
                <span>
                  {t('I Accept the terms and conditions')}
                </span>
              </label>
              {formik.touched.agree && formik.errors.agree && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.agree}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='text-center'>
              <ButtonIndicator
                type={'submit'}
                label={t('Submit')}
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || !formik.isValid || !formik.values.agree}
                data-cy={'btn-accept-terms'}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AcceptTermsAndConditions;
