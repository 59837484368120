import React, {useMemo} from 'react';
import I18n from "../../../../_mesomb/i18n";
import * as Yup from "yup";
import FormBuilder from "../../../../_mesomb/partials/form/FormBuilder.tsx";
import {BusinessModel, FormAction, UserModel} from "../../../../types";
import {addressFieldSchema} from "../../../../_mesomb/partials/form/AddressInput.tsx";
import {useTranslation} from "react-i18next";

export interface BusinessFormProps {
  action: FormAction;
  onSubmit: (values: UserModel) => void;
  profile?: BusinessModel;
}

function BusinessForm({action, onSubmit, profile, ...props}: BusinessFormProps) {
  const {t} = useTranslation();
  const formSchema = useMemo(() => Yup.object().shape({
    legal_form: Yup.string().oneOf(['SARL', 'SA', 'SAS', 'ETS', 'OTHER']).required(t('Field_is_required')),
    name: Yup.string().required(t('Field_is_required')),
    contact_email: Yup.string().email(t('Invalid email address')).required(t('Field_is_required')),
    contact_phone: Yup.string().required(t('Invalid email address')),
    trade_register_number: Yup.string().required(t('Field_is_required')),
    taxpayer_card_number: Yup.string().required(t('Field_is_required')),
    logo: Yup.string().required(t('Field_is_required')),
    trade_register: Yup.string().required(t('Field_is_required')),
    taxpayer_card: Yup.string().required(t('Field_is_required')),
    address: addressFieldSchema,
    // b_party: Yup.string().matches(/^[0-9]{8,9}$/, t('Invalid_phone_number')).required(t('Field_is_required')),
  }), [])
  type FormBusinessProfileValues = Yup.InferType<typeof formSchema>;

  return (
    <FormBuilder<FormBusinessProfileValues, UserModel>
      fieldsets={[{
        cols: [
          {
            size: 'col-lg-6 col-md-12',
            fields: [
              {
                type: 'select',
                name: 'legal_form',
                label: t('Legal Form'),
                options: [
                  {value: 'SARL', label: 'Société à Responsabilité Limitée'},
                  {value: 'SA', label: 'Société Anonyme'},
                  {value: 'SAS', label: 'Société par Actions Simplifiées'},
                  {value: 'ETS', label: 'Établissement'},
                  {value: 'OTHER', label: t('Other Legal Form')},
                ]
              },
              {
                type: 'text',
                name: 'name',
                label: t('Name'),
                // placeholder: t('The public name of your service')
              },
              {
                type: 'text',
                name: 'trade_register_number',
                label: t('Trade Register Number'),
                file: profile?.trade_register
              },
              {
                type: 'text',
                name: 'taxpayer_card_number',
                label: t('Tax Payer Card Number'),
                file: profile?.taxpayer_card
              },
            ]
          }, {
            size: 'col-lg-6 col-md-12',
            fields: [
              {
                name: 'contact_email',
                label: I18n.t('Contact Email'),
                type: 'email',
              },
              {
                name: 'contact_phone',
                label: I18n.t('Phone Number'),
                type: 'phone',
              },
              {
                name: 'address',
                label: I18n.t('Address'),
                type: 'address',
              },
            ]
          }
        ]
      }, {
        cols: [
          {
            size: 'col-lg-4 col-md-12',
            fields: [
              {
                name: 'logo',
                label: t('Logo'),
                placeholder: t('Upload the logo of your company'),
                type: 'dropzone',
                file: profile?.logo
              },
            ]
          },
          {
            size: 'col-lg-4 col-md-12',
            fields: [
              {
                name: 'trade_register',
                label: t('Trade Register'),
                placeholder: t('Upload the trade register'),
                type: 'dropzone',
                file: profile?.trade_register,
                accept: {'image/*': [], 'application/pdf': []},
              },
            ]
          },
          {
            size: 'col-lg-4 col-md-12',
            fields: [
              {
                name: 'taxpayer_card',
                label: t('Unique Tax Identification'),
                placeholder: t('Upload your NIU'),
                type: 'dropzone',
                file: profile?.taxpayer_card,
                accept: {'image/*': [], 'application/pdf': []},
              },
            ]
          },
        ],
      }]}
      formSchema={formSchema}
      className={''}
      action={action}
      onSubmit={onSubmit}
      initialValues={{
        legal_form: profile?.legal_form ?? 'SARL',
        name: profile?.name ?? '',
        contact_email: profile?.contact_email ?? '',
        contact_phone: profile?.contact_phone ?? '',
        trade_register_number: profile?.trade_register_number ?? '',
        taxpayer_card_number: profile?.taxpayer_card_number ?? '',
        logo: String(profile?.logo?.id ?? ''),
        trade_register: String(profile?.trade_register?.id ?? ''),
        taxpayer_card: String(profile?.taxpayer_card?.id ?? ''),
        address: {
          address1: profile?.address?.address1 ?? '',
          address2: profile?.address?.address2 ?? '',
          town: profile?.address?.town ?? '',
          region: profile?.address?.region ?? '',
          zip: profile?.address?.zip,
          country: profile?.address?.country ?? 'CM',
        },
      }}
      {...props}
    />
  );
}

export default BusinessForm;
