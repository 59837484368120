import React from 'react';
import {KTIcon} from "../../helpers";
import {Modal} from "react-bootstrap";
import CopiableText from "./CopiableText.tsx";

export interface HModalProps {
  show: boolean;
  handleClose: () => void;
  title?: string;
  children: React.ReactNode;
  backdrop?: boolean;
  closable?: boolean;
  padding?: number;
  width?: number;
  subtitle?: string;
  footer?: React.ReactNode;
}

function HModal({show, handleClose, title, children, backdrop = true, closable = true, padding = 10, width = 900, subtitle, footer}: HModalProps) {
  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered mw-${width}px`}
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={backdrop}
    >
      <div className='modal-header'>
        {title && <div style={{width: '100%'}}>
          <h3 className="modal-title">{title}</h3>
          {subtitle && (<span className="fw-light" data-cy={'modal-subtitle'}>{subtitle}</span>)}
        </div>}
        {closable && <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross'/>
        </div>}
      </div>
      <div className={`modal-body py-lg-${padding} px-lg-${padding}`}>
        {children}
      </div>
      {footer && (
        <div className='modal-footer'>
          {footer}
        </div>
      )}
    </Modal>
);
}

export default HModal;
