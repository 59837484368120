import React, {useMemo} from 'react';
import I18n from "../../../../_mesomb/i18n";
import i18n from "../../../../_mesomb/i18n";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import FormBuilder from "../../../../_mesomb/partials/form/FormBuilder.tsx";
import banks from '../../../../_mesomb/assets/data/banks.json'
import {BankAccountModel, FormAction} from "../../../../types";
import {bankRIBSchema} from "../../../../_mesomb/partials/form/BankRIBField.tsx";


export interface BankAccountFormProps {
  action: FormAction;
  onSubmit: (values: BankAccountModel) => void;
  selected?: BankAccountModel;
}


function BankAccountForm({action, onSubmit, selected, ...props}: BankAccountFormProps) {
  const {t} = useTranslation()
  const formSchema = useMemo(() => Yup.object().shape({
    bank_rib: bankRIBSchema,
    bank: Yup.string().required(t('Field_is_required')),
    name: Yup.string().required(t('Field_is_required')),
  }), [])
  type FormBankAccountValues = Yup.InferType<typeof formSchema>;

  return (
    <FormBuilder<FormBankAccountValues, BankAccountModel>
      fieldsets={[{
        cols: [
          {
            fields: [
              {
                type: 'bank_rib',
                name: 'bank_rib',
                label: i18n.t('Bank RIB'),
              },
              {
                name: 'bank',
                placeholder: I18n.t('Select the bank'),
                type: 'select2',
                label: i18n.t('Bank'),
                options: banks
              },
              {
                type: 'text',
                name: 'name',
                label: i18n.t('Name'),
                placeholder: i18n.t('Label of your account')
              },
            ]
          }
        ]
      }]}
      formSchema={formSchema}
      className={''}
      action={action}
      onSubmit={onSubmit}
      initialValues={{
        bank_rib: {
          bank_code: selected?.bank_code ?? '',
          agence_code: selected?.agence_code ?? '',
          account_number: selected?.account_number ?? '',
          rib_key: selected?.rib_key ?? '',
        },
        bank: selected?.bank ?? '',
        name: selected?.name ?? '',
      }}
      {...props}
    />
  );
}

export default BankAccountForm;
