import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {AppConfig} from "../../types";

export interface ConfigsSliceState {
  configs: AppConfig
}

const initialState: ConfigsSliceState = {
  configs: {
    providers: [],
    operations: [],
    products: [],
    technologies: [],
    platforms: []
  }
}

export const configsSlice = createAppSlice({
  name: 'configs',
  initialState,
  reducers: create => ({
    setConfigs: create.reducer((state, action: PayloadAction<AppConfig>) => {
      state.configs = action.payload
    }),
  }),
  selectors: {
    selectConfigs: configs => configs.configs,
  },
});

export const { setConfigs } =
  configsSlice.actions

export const { selectConfigs } = configsSlice.selectors
