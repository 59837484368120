import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {FundCategoryModel, FundModel} from "../../types";

export interface FundSliceState {
  categories: FundCategoryModel[];
  funds: FundModel[];
  fund?: FundModel;
}

const initialState: FundSliceState = {
  funds: [],
  categories: [],
  fund: undefined,
}

export const fundSlice = createAppSlice({
  name: 'fund',
  initialState,
  reducers: create => ({
    setCategories: create.reducer((state, action: PayloadAction<FundCategoryModel[]>) => {
      state.categories = action.payload?.filter(a => !!a) ?? [];
    }),
    setFunds: create.reducer((state, action: PayloadAction<FundModel[]>) => {
      state.funds = action.payload?.filter(a => !!a) ?? [];
    }),
    setFund: create.reducer((state, action: PayloadAction<FundModel | undefined>) => {
      state.fund = action.payload;
      const index = state.funds.findIndex(a => a.key === action.payload?.key)
      if (index === -1) {
        state.funds.push(action.payload!)
      } else {
        state.funds[index] = action.payload!
      }
    }),
  }),
  selectors: {
    selectCategories: fund => fund.categories,
    selectFunds: fund => fund.funds,
    selectFund: fund => fund.fund,
  },
});

export const { setFunds, setFund, setCategories } =
  fundSlice.actions

export const { selectFunds, selectFund, selectCategories } = fundSlice.selectors
