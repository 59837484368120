import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {TeamMemberModel, BankAccountModel} from "../../types";

export interface AuthSliceState {
  teams: TeamMemberModel[];
  bankAccounts: BankAccountModel[];
}

const initialState: AuthSliceState = {
  teams: [],
  bankAccounts: [],
}

export const accountSlice = createAppSlice({
  name: 'account',
  initialState,
  reducers: create => ({
    setTeams: create.reducer((state, action: PayloadAction<TeamMemberModel[]>) => {
      state.teams = action.payload.filter(a => !!a);
    }),
    setBankAccounts: create.reducer((state, action: PayloadAction<BankAccountModel[]>) => {
      state.bankAccounts = action.payload.filter(a => !!a);
    }),
  }),
  selectors: {
    selectTeams: account => account.teams,
    selectBankAccounts: account => account.bankAccounts,
  },
});

export const { setTeams, setBankAccounts } =
  accountSlice.actions

export const { selectTeams, selectBankAccounts } = accountSlice.selectors
