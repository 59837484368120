import React from 'react';
import {Form, Formik} from "formik";
import FormField from "./FormField.tsx";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import axios from "axios";
import {FormAction, FormFieldProps, FormValues} from "../../../types";
import * as Yup from "yup";
import ButtonIndicator from "../components/ButtonIndicator.tsx";
import {FormikValues} from "formik/dist/types";
import {captureException} from "@sentry/react";
import {processErrorResponse} from "../../utils";

export interface FormBuilderProps<T extends FormValues, S> {
  fieldsets: {
    title?: string;
    cols: {
      size?: string;
      fields: FormFieldProps<T>[]
    }[];
  }[];
  formSchema: Yup.ObjectSchema<T>;
  className?: string;
  action?: FormAction;
  onSubmit?: (data: S, values: T) => Promise<void>;
  initialValues: T;
  submitText?: string;
  onCancel?: () => void;
}

function FormBuilder<T extends FormikValues, S>({fieldsets, formSchema, className = 'card', action, onSubmit, initialValues, submitText, onCancel}: FormBuilderProps<T, S>) {
  const {t} = useTranslation();
  return (
    <Formik<T>
      validationSchema={formSchema}
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
      actions.setSubmitting(true)
      try {
        if (action) {
          const {data} = await axios.request<S>({
            url: action.path,
            method: action.method || 'post',
            data: {
              ...values,
              ...(values.full_name ?? {}),
              ...(values.bank_rib ?? {}),
              ...(values.address ?? {}),
            },
            responseType: action.responseType || 'json'
          });
          if(onSubmit) await onSubmit(data, values)
        } else {
          if(onSubmit) await onSubmit(values as unknown as S, values)
        }
      } catch (e) {
        processErrorResponse(e, actions.setErrors)
        captureException(e);
      } finally {
        actions.setSubmitting(false)
      }
    }}>
      {({isSubmitting, isValid, touched, errors}) => {
        return (
          <Form className={clsx('row', className)} id="kt_create_application" method="post">
            {fieldsets.map(({cols}, index) => (
              <div className={'row'} key={`fieldset-${index}`}>
                {cols.map(({size, fields}) => (
                  <div className={clsx(size ?? 'col-sm-12')} key={fields.map(f => f.name).join('-')}>
                    {fields.map(field => (
                      <FormField
                        key={String(field.name)}
                        touched={touched[field.name] as boolean}
                        error={errors[field.name]}
                        {...field}
                      />
                    ))}
                  </div>
                ))}
              </div>
            ))}
            {/*{Array(nbrCols).fill(0).map((item, index) => (
              <div className={`col-sm-${12 / nbrCols}`} key={`col-${index}`}>
                {fields.filter(f => (f.col || 1) === index + 1).map(field => {
                  if (field.type === 'group') {
                    return (
                      <div className='row'>
                        {field.items?.map(f => (
                          <div key={f.name} className={'col-sm-6'}>
                            <FormField
                              touched={touched}
                              errors={errors}
                              {...f}
                            />
                          </div>
                        ))}
                      </div>
                    )
                  }
                  return (
                    <FormField
                      key={String(field.name)}
                      touched={touched}
                      errors={errors}
                      {...field}
                    />
                  )
                })}
              </div>
            ))}*/}
            <div className='d-grid card-footer d-flex flex-wrap justify-content-end py-0 px-6'>
              {onCancel && (
                <ButtonIndicator
                  label={t('Cancel')}
                  type={'button'}
                  className={'me-2 btn btn-light'}
                  onClick={onCancel}
                  marginTop={0}
                />
              )}
              <ButtonIndicator
                type={'submit'}
                label={submitText ?? t('Submit')}
                disabled={isSubmitting || !isValid || !touched}
                loading={isSubmitting}
                data-cy={'submit-button'}
                marginTop={0}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  );
}

export default FormBuilder;
