import {FC} from 'react'
import HIcon from "../../../../app/modules/payment/components/HIcon.tsx";
import {useAppDispatch, useAppSelector} from "../../../../app/store/hooks.ts";
import {selectUser, setUser} from "../../../../app/store/auth.ts";
import axios from "axios";
import {useTranslation} from "react-i18next";

const trustLevelColors: Record<string, string> = {
  UNVERIFIED: 'danger',
  BASIC: 'warning',
  VERIFIED: 'success',
  TRUSTED: 'success',
  SUPER_USER: 'success',
}

const AsideUserMenu: FC = () => {
  const currentUser = useAppSelector(selectUser);
  const {t} = useTranslation()
  const dispatch = useAppDispatch();

  return (
    <>
      <div className='menu-item'>
        <div className={'menu-link'}>
          <div className="form-check form-switch form-check-custom form-check-solid me-10">
            <input
              className="form-check-input h-20px w-30px"
              type="checkbox" disabled={currentUser?.trust_level?.level == 'UNVERIFIED'}
              checked={true} id="flexSwitch20x30"
              onChange={async (e) => {
                try {
                  const {data} = await axios.put('/profile/live/mode/', {
                    mode: currentUser?.in_live_mode ? 'TEST' : 'LIVE'
                  })
                  dispatch(setUser(data));
                } catch (e: any) {

                }
              }}
            />
            <label className="form-check-label fw-bold fs-5" htmlFor="flexSwitch20x30">
              {currentUser?.in_live_mode ? t('In Live Mode') : t('In Test Mode')}
            </label>
          </div>
        </div>
      </div>
      <div className='d-flex flex-stack' id={'user-profile-menu'}>
        {/* begin::Wrapper */}
        <a href={'/settings'} data-cy={'user-profile-menu'}>
          <div className='d-flex align-items-center'>
            {currentUser && <HIcon image={currentUser?.avatar?.path} size={40} text={currentUser?.full_name} />}
            <div className='ms-2'>
            <span className='text-gray-800 text-hover-primary fs-6 fw-bolder lh-1' style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
              {currentUser?.full_name}
            </span>
              <span className='text-muted fw-bold d-block fs-7 lh-1'>{currentUser?.email}</span>
              <span className={`text-${trustLevelColors[currentUser?.trust_level?.level ?? 'UNVERIFIED']} fw-semibold`}>{t(`Label_trust_level_${currentUser?.trust_level.level}`)}</span>
            </div>
          </div>
        </a>
        {/* end::Wrapper */}

        {/*/!* begin::User menu *!/*/}
        {/*<div className='ms-1'>*/}
        {/*  <div*/}
        {/*    className='btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'*/}
        {/*    data-kt-menu-trigger='click'*/}
        {/*    data-kt-menu-overflow='false'*/}
        {/*    data-kt-menu-placement='top-end'*/}
        {/*  >*/}
        {/*    <KTIcon iconName='setting-2' className='fs-1' />*/}
        {/*  </div>*/}
        {/*  <UserMenu />*/}
        {/*</div>*/}
        {/*/!* end::User menu *!/*/}
      </div>
    </>
  )
}

export {AsideUserMenu}
