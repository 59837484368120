import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {BulkPaymentListModel} from "../../types";

export interface AuthSliceState {
  bulkPaymentLists: BulkPaymentListModel[]
}

const initialState: AuthSliceState = {
  bulkPaymentLists: [],
}

export const bulkPaymentSlice = createAppSlice({
  name: 'bulkPayment',
  initialState,
  reducers: create => ({
    setBulkPaymentLists: create.reducer((state, action: PayloadAction<BulkPaymentListModel[]>) => {
      state.bulkPaymentLists = action.payload
    }),
    setBulkPaymentList: create.reducer((state, action: PayloadAction<BulkPaymentListModel | undefined>) => {
      // state.paymentLink = action.payload;
      const index = state.bulkPaymentLists.findIndex(a => a.id === action.payload?.id)
      if (index === -1) {
        state.bulkPaymentLists.push(action.payload!)
      } else {
        state.bulkPaymentLists[index] = action.payload!
      }
    }),
  }),
  selectors: {
    selectBulkPaymentLists: paymentLink => paymentLink.bulkPaymentLists,
    // selectPaymentLink: paymentLink => paymentLink.paymentLink,
  },
});

export const { setBulkPaymentLists, setBulkPaymentList } =
  bulkPaymentSlice.actions

export const { selectBulkPaymentLists } = bulkPaymentSlice.selectors
